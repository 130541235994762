<script>
import {mdiClose} from "@mdi/js";
import {getSetStatusBtnText, getStatusColor, statusKeys} from "@/services/mappers";
import Status from "@/components/Status.vue";
import {acceptFormular, declineFormular, saveFormular} from "@/services/api/requests";

export default {
  name: "CardTimelineNew",
  components: {Status},
  model: {
    prop: "selectedOperation",
    event: "input",
  },
  props: {
    selectedOperation: {
      type: String,
      default: "",
    },
    operations: {
      type: Array,
      default: () => [],
    },
    formularId: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      step: 1,
      comment: "",
      sending: false,
      commentRequired: [statusKeys.declined],
      availableOperations: statusKeys,
      icons: {
        close: mdiClose,
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.selectedOperation;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    disableSendBtn() {
      return this.disabled || this.sending || (this.commentRequired.includes(this.model) && !this.comment);
    },
  },
  methods: {
    getSetStatusBtnText,
    color(status) {
      return getStatusColor(status);
    },
    onSelect(operation) {
      this.model = operation;
      this.step = 2;
    },
    onBack() {
      this.model = "";
      this.comment = "";
      this.step = 1;
    },
    async onSend() {
      this.sending = true;
      this.$emit("sending", true);
      try {
        if (this.selectedOperation === statusKeys.declined) {
          await declineFormular(this.formularId, this.comment);
        }
        if (this.selectedOperation === statusKeys.accepted) {
          await acceptFormular(this.formularId, this.comment);
        }
        if (this.selectedOperation === statusKeys.ready) {
          await saveFormular(this.formularId, this.comment);
        }
        this.$emit("success")
        this.step = 1;
        this.comment = "";
        this.model = "";
      } catch (e) {
        console.log(e);
      } finally {
        this.$emit("sending", false);
        this.sending = false;
      }
    },
  }
}
</script>

<template>
  <v-card
    flat
    color="transparent"
  >
    <v-stepper
      v-model="step"
      class="elevation-0 new-item-stepper"
    >
      <v-stepper-content step="1">
        <v-card-text>
          <template v-if="operations.length === 0">
            <v-alert
              type="info"
              dense
              class="mb-2"
            >
              Нет доступных действий
            </v-alert>
          </template>
          <template v-else>
            <p>Доступные действия:</p>
            <v-chip
              v-for="operation in availableOperations"
              v-show="operations.includes(operation)"
              :key="`chip-${operation}`"
              label
              :disabled="disabled"
              class="chip-operation mr-1 mb-1"
              :color="color(operation)"
              @click="onSelect(operation)"
            >
              {{ getSetStatusBtnText(operation) }}
            </v-chip>
          </template>
        </v-card-text>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card-title class="status-title">
          <Status
            v-if="selectedOperation"
            :status="selectedOperation"
            small
            style="margin-left: -1px"
            @click="onBack"
          />
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="comment"
            :flat="$vuetify.breakpoint.lgAndUp"
            :solo="$vuetify.breakpoint.lgAndUp"
            no-resize
            auto-grow
            :rows="1"
            :row-height="18"
            :outlined="$vuetify.breakpoint.mdAndDown"
            placeholder="Комментарий"
          >
            <template
              v-if="$vuetify.breakpoint.lgAndUp"
              #append
            >
              <v-btn
                depressed
                color="primary"
                :loading="sending"
                :disabled="disableSendBtn"
                @click="onSend"
              >
                Отправить
              </v-btn>
            </template>
          </v-textarea>
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            color="primary"
            style="float: right"
            :large="$vuetify.breakpoint.smAndDown"
            :block="$vuetify.breakpoint.smAndDown"
            :loading="sending"
            :disabled="disableSendBtn"
            @click="onSend"
          >
            Отправить
          </v-btn>
        </v-card-text>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<style scoped>
.new-item-stepper {
  background-color: transparent !important;
}
.new-item-stepper > .v-stepper__content {
  padding: 0 !important;
}
.chip-operation {
  border-radius: 0 !important;
}
</style>
