<script>
import {
  downloadExcel,
  getFormular,
  getMonthPremiumByManager,
  putMonthPremium
} from "@/services/api/requests";
import {mapGetters} from "vuex";
import FormularHistory from "@/components/FormularHistory.vue";
import CardUser from "@/components/CardUser.vue";
import Status from "@/components/Status.vue";
import {getMonthName, statusKeys} from "@/services/mappers";
import LabelDeadline from "@/components/LabelDeadline.vue";

export default {
  name: "Formular",
  components: {LabelDeadline, Status, CardUser, FormularHistory},
  props: {
    formularId: {
      type: [String, Number],
      required: true
    },
  },
  data: function () {
    return {
      loadingFormularTable: false,
      loadingFormular: false,
      items: [],
      sendingStatusChange: false,
      readyToSave: false,
      formular: null,
      downloadingExcelReport: false,
      headers: [
        {
          text: 'Пользователь',
          align: 'start',
          value: 'user',
          sort: (a, b) => {
            if (a.user_name < b.user_name) return -1;
            if (a.user_name > b.user_name) return 1;
            return 0;
          }
        },
        {
          text: 'Премия',
          align: 'start',
          value: 'premium_money'
        },
        {
          text: 'Плановые часы',
          align: 'start',
          value: 'hours_plan'
        },
        {
          text: 'Фактические часы',
          align: 'start',
          value: 'hours_fact'
        },
        {
          text: 'Коэффициент загрузки производства',
          align: 'start',
          sortable: false,
          value: 'efficiency_coef'
        },
        {
          text: 'Индивидуальный коэффициент',
          align: 'start',
          value: 'individual_coef'
        },
        {
          text: 'Итоговый коэффициент',
          align: 'start',
          value: 'total_coef'
        },
        {
          text: 'Оплата',
          align: 'start',
          value: 'payment'
        },
      ],
      page: 1,
      itemsPerPage: 10,
    }
  },
  computed: {
    ...mapGetters("User", {
      getUser: 'getUser',
      userId: 'getId',
      isAdmin1: 'isAdmin1',
      isManager1: 'isManager1',
    }),
    canEdit() {
      return this.editableFormular
    },
    disabledActions() {
      return !this.readyToSave || this.loadingFormularTable || this.loadingFormular
    },
    disableIndividual() {
      return this.loadingFormularTable || this.loadingFormular || this.sendingStatusChange
    },
    editableFormular() {
      const isSameUser = this.userId === this.formularManager.id
      const isEditableStatus =
        [statusKeys.new, statusKeys.declined, statusKeys.in_progress].includes(this.formularStatus)
      return isSameUser && isEditableStatus
    },
    formularHistory() {
      if (!this.formular) {
        return []
      }
      return this.formular.history
    },
    formularMonth() {
      if (!this.formular) {
        return null
      }
      const m = this.formular.date.split('-')[1]
      return this.getMonthName(m)
    },
    formularYear() {
      if (!this.formular) {
        return null
      }
      return this.formular.date.split('-')[0]
    },
    formularManager() {
      if (!this.formular) {
        return null
      }
      return this.formular.manager
    },
    formularStatus() {
      if (!this.formular) {
        return ""
      }
      return this.formular.status
    },
    showDeadline() {
      return this.formular?.deadline
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    getMonthName,
    fetch() {
      this.fetchItems()
      this.fetchFormular()
      this.readyToSave = true
    },
    async fetchFormular() {
      this.loadingFormular = true
      try {
        this.formular = await getFormular(this.formularId)
      } catch (e) {
        this.formular = null
        console.log(e)
      }
      this.loadingFormular = false
    },
    async fetchItems() {
      this.loadingFormularTable = true
      try {
        this.items = await getMonthPremiumByManager(this.formularId)
      } catch (e) {
        this.items = []
        console.log(e)
      }
      this.loadingFormularTable = false
    },

    async changeIndividualCoefficient(id, value) {
      this.loadingFormularTable = true
      try {
        // find item by id
        const index = this.items.findIndex(item => item.id === id)
        if (index !== -1) {
          this.items[index].individual_coef = value
        }
        await putMonthPremium(id, {
          individual_coef: value
        })
        this.fetch()
      } catch (e) {
        console.log(e)
      }
      this.loadingFormularTable = false
    },
    getLocalDate(date) {
      return new Date(date).toLocaleDateString()
    },
    getCurrency(value) {
      return value.toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})
    },
    async downloadExcelReport() {
      this.downloadingExcelReport = true
      const link = await downloadExcel(this.formularId)
      link.download = `Премии_формуляр_${this.formularMonth}_${this.formularYear}.pdf`;
      document.body.appendChild(link);
      link.click();
      setTimeout(()=> this.downloadingExcelReport = false, 100)
    },
  }
}
</script>

<template>
  <v-container class="table">
    <v-card
      v-if="loadingFormular"
      outlined
    >
      <div

        class="text-center d-flex flex-column justify-center align-center my-10"
        style="width: 100%"
      >
        <v-progress-circular
          indeterminate
          height="60"
          color="primary"
        />
        <span class="mt-5">Загружается формуляр</span>
      </div>
    </v-card>
    <template v-else>
      <v-card outlined>
        <v-card-title>
          Формуляр №{{ formularId }} - {{ formularMonth }} {{ formularYear }}
          <LabelDeadline
            class="ml-2"
            :deadline="formular.deadline"
            :small="!canEdit"
          />
        </v-card-title>
        <v-card-text>
          <div class="formular_labels">
            <CardUser :user="formularManager" />
            <Status
              v-if="formularStatus"
              small
              class="ml-2"
              :status="formularStatus"
            />
          </div>
        </v-card-text>
        <v-card-text>
          <span class="mr-2">Скачать PDF файл формуляра:</span>
          <v-btn
            color="primary"
            depressed
            small
            :loading="downloadingExcelReport"
            @click="downloadExcelReport"
          >
            Скачать PDF
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-data-table
            must-sort
            sort-by="name"
            :multi-sort="false"
            :sort-desc="false"
            :headers="headers"
            :items-per-page.sync="itemsPerPage"
            :items="items"
            :loading="loadingFormularTable"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50],
              showCurrentPage: true
            }"
            :page.sync="page"
          >
            <template #item.user="{item}">
              <CardUser :user="item.user" />
            </template>
            <template #item.date="{item}">
              {{ getLocalDate(item.date) }}
            </template>
            <template #item.premium_money="{item}">
              {{ getCurrency(item.premium_money) }}
            </template>
            <template #item.hours_plan="{item}">
              {{ item.hours_plan }}
            </template>
            <template #item.hours_fact="{item}">
              {{ item.hours_fact }}
            </template>
            <template #item.efficiency_coef>
              {{ formular.efficiency_coef }}
            </template>
            <template #item.individual_coef="{item}">
              <v-select
                v-if="canEdit"
                :value="item.individual_coef"
                :disabled="disableIndividual"
                hide-details
                :items="[0, 0.5, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2]"
                flat
                type="number"
                pattern="/^\d*\.?\d*$/"
                outlined
                dense
                @input="readyToSave = false"
                @change="changeIndividualCoefficient(item.id, $event)"
              />
              <template v-else>
                {{ item.individual_coef }}
              </template>
            </template>
            <template #item.total_coef="{item}">
              {{ item.total_coef }}
            </template>
            <template #item.payment="{item}">
              {{ getCurrency(item.payment) }}
            </template>
            <template #no-data>
              {{ $t("no-data") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <FormularHistory
        v-if="formularHistory"
        :history="formularHistory"
        :formular-id="formularId"
        :current-status="formularStatus"
        :disabled="disabledActions"
        :can-edit="canEdit"
        @sending-status-change="sendingStatusChange = $event"
        @status-changed="fetchFormular"
      />
    </template>
  </v-container>
</template>

<style scoped lang="scss">
.formular_labels {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>