<script>
import {mdiAccountCircleOutline} from "@mdi/js";

export default {
  name: "CardUser",
  props: {
    user: {
      type: Object,
      required: true,
    },
    xSmall: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      mdiAccountCircleOutline,
    };
  },
  computed: {
    name() {
      if (!this.user.user_name)
        return "";
      const names = this.user.user_name.split(" ");
      if (names.length === 0)
        return "";
      if (names.length === 1)
        return names[0];
      if (names.length === 2)
        return `${names[0]} ${names[1].charAt(0)}.`;
      if (names.length >= 3)
        return `${names[0]} ${names[1].charAt(0)}. ${names[2].charAt(0)}.`;
      return "";
    },
  },
}
</script>

<template>
  <v-menu
    bottom
    offset-y
  >
    <template #activator="{on, attrs}">
      <v-chip
        :small="!xSmall"
        :x-small="xSmall"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mr-2">
          {{ mdiAccountCircleOutline }}
        </v-icon>
        {{ name }}
      </v-chip>
    </template>
    <v-list three-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            <strong>Пользователь</strong>
            <v-chip
              small
              label
              class="ml-2"
            >
              #{{ user.personal_number }}
            </v-chip>
          </v-list-item-subtitle>
          <v-list-item-title>
            {{ user.user_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.role }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            <strong>Должность и обособленное подразделение</strong>
          </v-list-item-subtitle>
          <v-list-item-title>
            <template v-if="user.job_position && user.job_position !== 'nan'">
              {{ user.job_position }}
            </template>
            <span
              v-else
              class="font-italic"
            >
              Позиция не указана
            </span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <template v-if="user.department && user.department !== 'nan'">
              {{ user.department }}
            </template>
            <span
              v-else
              class="font-italic"
            >
              Подразделение не указано
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>