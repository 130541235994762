<script>
import {getStatusIcon, statusKeys} from "@/services/mappers";
import Status from "@/components/Status.vue";
import CardTimelineNew from "@/components/CardTimelineNew.vue";
import {mapGetters} from "vuex";
import CardUser from "@/components/CardUser.vue";

export default {
  name: "FormularHistory",
  components: {CardUser, CardTimelineNew, Status},
  props: {
    history: {
      type: Array,
      default: () => []
    },
    currentStatus: {
      type: String,
      default: null
    },
    formularId: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      operations: [],
      operation: null,
    };
  },
  computed: {
    ...mapGetters("User", {
      isAdmin1: 'isAdmin1',
      isManager1: 'isManager1',
    }),
    historyForDisplay() {
      return this.history
    },
  },
  watch: {
    currentStatus() {
      this.operations = this.getAvailableOperations();
    }
  },
  created() {
    this.operations = this.getAvailableOperations();
  },
  methods: {
    getStatusIcon,
    toLocaleTime(time) {
      return new Date(time*1000).toLocaleString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
    },
    onUpdatedStatus(payload) {
      this.operation = payload;
    },
    getAvailableOperations() {
      switch (this.currentStatus) {
        case statusKeys.new:
          return [];
        case statusKeys.in_progress:
          return this.canEdit ? [statusKeys.ready] : [];
        case statusKeys.ready:
          return this.isAdmin1 ? [statusKeys.accepted, statusKeys.declined] : [];
        case statusKeys.declined:
          return this.isAdmin1 ? [statusKeys.accepted] : [];
        case statusKeys.accepted:
          return this.isAdmin1 ? [statusKeys.declined] : [];
        case statusKeys.signed:
          return this.isAdmin1 ? [statusKeys.declined] : [];
        default:
          return [];
      }
    },
    onSuccessfulStatusChange() {
      this.$emit("status-changed");
    },
    onSendingStatusChange() {
      this.$emit("sending-status-change");
    },
  }
}
</script>

<template>
  <v-timeline
    dense
    align-top
    :class="operations.length ? '' : 'final-application-status'"
  >
    <v-timeline-item
      v-for="(comment, index) in historyForDisplay"
      :key="`timeline-item-${index}`"
      color="#e0e0e0"
      fill-dot
    >
      <template #icon>
        <v-icon>{{ getStatusIcon(comment.new_status) }}</v-icon>
      </template>
      <v-card flat>
        <v-card-title class="status-title">
          <Status
            :status="comment.new_status"
            style="margin-left: -1px"
            small
          />
        </v-card-title>
        <v-card-subtitle>
          <p
            style="text-align:left"
            class="mb-0"
          >
            <span
              v-if="$vuetify.breakpoint.mdAndUp"
              style="float:right;text-align:right"
            >
              {{ toLocaleTime(comment.change_time) }}
              <br>
              <CardUser
                v-if="comment.change_person"
                :user="comment.change_person"
                x-small
              />
            </span>
          </p>
          <p
            v-if="$vuetify.breakpoint.smAndDown"
            class="mb-0 pt-2"
            style="text-align:left"
          >
            {{ toLocaleTime(comment.change_time) }}
            <br>
            <CardUser
              v-if="comment.change_person"
              :user="comment.change_person"
              x-small
            />
          </p>
        </v-card-subtitle>
        <v-card-text>
          <span v-if="comment.comment">{{ comment.comment }}</span>
          <span
            v-else
            class="font-italic"
          >Нет комментария</span>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item
      v-if="operations.length"
      key="timeline-item-new"
      :hide-dot="!operation"
      :fill-dot="!!operation"
      color="#e0e0e0"
    >
      <template
        v-if="operation"
        #icon
      >
        <v-icon>{{ getStatusIcon(operation) }}</v-icon>
      </template>
      <CardTimelineNew
        v-model="operation"
        :operations="operations"
        :formular-id="formularId"
        :disabled="disabled"
        @sending="onSendingStatusChange"
        @success="onSuccessfulStatusChange"
        @operation="onUpdatedStatus"
        @updated-status="onUpdatedStatus"
      />
    </v-timeline-item>
  </v-timeline>
</template>

<style>
.status-title {
  padding-top: 7px !important;
}
.v-timeline {
  border-bottom: rgba(0, 0, 0, 0.12) dashed 2px;
}
.final-application-status {
  border-bottom: rgba(0, 0, 0, 0.12) solid 2px;
}
.v-timeline-item__dot {
  box-shadow: none !important;
}
.float-to-left {
  float: left;
  margin-bottom: 0 !important;
}
.float-to-right {
  float: right;
  margin-bottom: 0 !important;
}
</style>